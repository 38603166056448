/**
 * 服务器返回来的数据如果是字符串数据格式为{status:<code>,message:<str>}。其他格式的数据{status:<code>:data:<data>}
 * 页面需要状态码的api（获取用户数据等），返回{status:<code>,data:<data>}格式
 * 页面不需要状态码的api（天气等）,返回data数据或者null
 */
import axios from 'axios'
import {getDetail, getLocalStorage} from "../utils";

// 获取前端网站地址(用户更多主题跳转等)
export const getFrontWebsite = async ()=>{
    try {
        const {data} = await axios.get("https://www.picknewtab.com/api/homepage",{params:{
                id:window.chrome.runtime.id
            }});
        return data.message
    }catch{
        return null
    }
}

// 获取搜索引擎的链接
export const getSearchUrl = async (url)=>{
    try {
        const {data} = await axios.get("https://www.picknewtab.com/api/search-url", { params:{url}});
        return data
    }catch{
        return null
    }
}

// 获取搜索框关键词下拉推荐（百度源）
export const getBaiduSug = async (kw)=>{
    try{
        const {data} = await axios.get('https://www.picknewtab.com/api/s/kw',{params:{kw}})
        return data
    }catch(e){
        return null
    }
}

// 获取默认的导航图标
export const getDefaultNavigation = async ()=>{
    try{
        const {data} = await axios.get('https://www.picknewtab.com/api/navigation/default')
        return data.data
    }catch(e){
        return null
    }
}

// 获取导航的分类
export const getNavigationLibraryCategories = async (language='zh')=>{
    try {
        const {data} = await axios.get("https://www.picknewtab.com/api/navigation/categories", {
            params: { lang: language },
        });
        return [{label:'全部',value:''},...data.data]
    } catch (error) {
        return null
    }
}

// 获取导航列表（参数：语言，类型，关键词，页数和数量）
export const getNavigationLibrary = async (options={})=>{
    try {
        let {lang,type,kw,page,offset} = options
        const { data } = await axios.get("https://www.picknewtab.com/api/navigation/list", {
            params: { type:type??'', kw:kw??'', page:page??1, offset:offset || 10, lang:lang??'zh'},
        });
        return data.data
    } catch (error) {
       return null
    }
}

// 获取网站的标题
export const getNavigationTitle = async (url)=>{
    if(!/^https?:\/\//)
        url = 'http://'+url
    try{
        const {data} = await axios.get('https://www.picknewtab.com/api/web/title',{params:{url}})
        return data
    }catch (e){
       return null
    }
}

// 获取网站的图标
export const getNavigationIcons = async (url)=>{
    if(!/^https?:\/\//)
        url = 'http://'+url
    try{
        const {data} = await axios.get('https://www.picknewtab.com/api/web/icons',{params:{url}})
        // const {data} = await axios.get('http://localhost:8000/api/web/icons',{params:{url}})
        return data.data
    }catch (e){
        return null
    }
}

// 获取天气
export const getWeather = async ()=>{
    try{
        const {data} = await axios.get('https://www.picknewtab.com/api/weather')
        return data.data
    }catch(e){
        return null
    }
}

// 获取主题列表(暂时未使用)
export const getThemeList = async ()=>{
    try{
        const {data} = await axios.get('https://www.picknewtab.com/api/theme/list')
        return data
    }catch(e){
        return null
    }
}

// 获取主题详情（主题的壁纸信息）
export const getTheme = async (type)=>{
    try{
        // const {data} = await axios.get('https://www.picknewtab.com/api/theme',{params:{id}})
        const {data} = await axios.post('https://www.jianyueku.com/api/index/getImages',{type})
        return data.data
    }catch(e){
        return null
    }
}

// 获取壁纸详情
export const getWallpaper = async (id)=>{
    try{
        const {data} = await axios.post('https://www.jianyueku.com/api/index/getImage',{id})
        return data.data
    }catch(e){
        return null
    }
}

// 根据壁纸id获取主题详情
export const getThemeWithWallpaper = async (id)=>{
    try{
        const wallpaper = await getWallpaper(id)
        return getTheme(wallpaper.type_id)
    }catch(e){
        return null
    }
}



// 用户数据恢复（参数:恢复到的节点）
export const recoveryUserData = async (backupTime)=>{
    const user = getLocalStorage('user-data'),
        token = user.token
    if(token){
        try{
            const { data } = await axios.post('https://www.jianyueku.com/api/user/recovery',{backup_time:backupTime},
                {headers:{Authorization:token}})
            return data
        }catch(e){
            return typeof e.response?.data === 'object'?e.response?.data : {code:40006, message:'服务器连接失败'}
        }
    }else{
        return {code:20007,message:'token已经失效'}
    }
}

// 用户数据备份
export const backupUserData = async (data)=>{
    const user = getLocalStorage('user-data'),
        token = user.token
    if(token){
        try{
            const {data:resData} = await axios.post('https://www.jianyueku.com/api/user/backup',data,
                {headers:{Authorization:token}})
            return resData
        }catch(e){
            return typeof e.response?.data === 'object' ? e.response?.data : {code: 40006, message: '服务器连接失败'}
        }
    }else{
        return {code:20007,message:'token已经失效'}
    }
}

// 用户登录（用户名，密码）
export const userLogin = async (username,password)=>{
    try{
        const {data} = await axios.post('https://www.jianyueku.com/api/login/userLogin',{username,password})
        return data
    }catch(e){
        const response = typeof e.response?.data === 'object'?e.response?.data : {code:40006, message:'服务器连接失败'}
        return {...response}
    }
}

// 用户注册 （用户名，密码）
export const userRegister = async (username,password)=>{
    try{
        const {data} = await axios.post('https://www.jianyueku.com/api/login/register',{username,password})
        return data
    }catch(e){
        const response = typeof e.response?.data === 'object'?e.response?.data : {code:40006, message:'服务器连接失败'}
        return {...response}
    }
}

// 检测用户名是否存在（不可重复注册）
export const checkUsernameIsExist = async (username)=>{
    try{
        const {data} = await axios.post('https://www.jianyueku.com/api/login/checkUsername',{username})
        return data
    }catch(e){
        const response = typeof e.response?.data === 'object'?e.response?.data : {code:40006, message:'服务器连接失败'}
        return {...response}
    }
}

// 检测用户备份数据与本地数据是否相同
export const checkBackupDataIsSame = async (backupTime,token)=>{
    if(!token){
        return {code:20004,message:'用户没有登录'}
    }
    try{
        const {data} = await axios.post('https://www.jianyueku.com/api/user/checkBackup',
            {backup_time:backupTime},{headers:{Authorization:token}})
        return data
    }catch(e){
        const response = typeof e.response?.data === 'object'?e.response?.data : {code:40006, message:'服务器连接失败'}
        return {...response}
    }
}

// 图片上传
export const uploadFile = async (files)=>{
    try{
        const details = getDetail()
        const user = getLocalStorage('user-data')
        const formData = new FormData()
        if(files instanceof FileList){
            for(let file of files){
                formData.append('files',file)
            }
        }else{
            formData.append('file',files)
        }
        const {data} = await axios.post('https://www.picknewtab.com/api/file/upload',formData,
        // const {data} = await axios.post('http://localhost:8000/api/file/upload',formData,
            {
                params:{...details},
                headers:{Authorization:user?.token ?? ''}
            })
        return data.data
    }catch(e){
        return null
    }
}

// 删除壁纸
export const deleteFile = async (url)=>{
    try{
        const details = getDetail()
        const user = getLocalStorage('user-data')
        const {data} = await axios.delete('https://www.picknewtab.com/api/file',
        // const {data} = await axios.delete('http://localhost:8000/api/file',
            {
                params:{...details,url},
                headers:{Authorization:user?.token ?? ''}
            })
        return data.data
    }catch(e){
        return null
    }
}

// 搜藏|取消收藏壁纸
export const favWallpaper = async (wallpaper)=>{
    const user = getLocalStorage('user-data'),
        token = user.token
    if(token){
        try{
            const {data} = await axios.post( 'https://www.jianyueku.com/api/user/wallpaper',
                {wallpaper},
                {headers:{Authorization:token}})
            return data
        }catch(e){
            return typeof e.response?.data === 'object' ? e.response?.data : {code: 40006, message: '服务器连接失败'}
        }
    }else{
        return {code:20007,message:'token已经失效'}
    }
}

// 获取收藏的壁纸
export const getFavWallpapers = async ()=>{
    return favWallpaper()
}

// 获取收藏的壁纸
// export const getFavWallpapers = async ()=>{
//     const user = getLocalStorage('user-data'),
//         token = user.token
//     if(token){
//         try{
//             const {data} = await axios.get('https://www.jianyueku.com/api/user/wallpaper',
//                 {headers:{Authorization:token}})
//             return data
//         }catch(e){
//             return typeof e.response?.data === 'object' ? e.response?.data : {code: 40006, message: '服务器连接失败'}
//         }
//     }else{
//         return {code:20007,message:'token已经失效'}
//     }
// }

export const qqLoginCheck = async (loginToken)=>{
    try{
        const {data} = await axios.post('https://www.jianyueku.com/api/login/checkLogin',{loginToken})
        return data
    }catch (e){
        return false
    }
}

export const stats = (type,data={})=>{
    if(window.chrome.runtime.id==='browser')return
    try{
        axios.post('https://www.jianyueku.com/api/user/census', {type,...data}).then(null).catch(null)
    }catch (e){
        console.error(e.message)
    }
}

// chrome-search://ntpicon/?size=48@4.000000x&url=http://picknewtab.com/