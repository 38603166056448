import {connect} from 'react-redux'
import {Modal as AntModal, Form, Input} from 'antd'
import {useEffect, useMemo, useRef, useState} from "react";
import {Translation} from 'react-i18next'
import {uploadFile,getNavigationIcons} from "../../api";
import {navigationForage, navigationIconGet} from "../../plugins/localforage";
import {nanoid} from "nanoid";

const {Item} = Form

function Edit(props){
    const [name,setName] = useState(props.state.name),
        [isDefaultIcon,setIsDefaultIcon] = useState(true),
        [url,setUrl] = useState(props.state.url),
        [icon,setIcon] = useState(props.state.icon),
        [errorTip,setErrorTip] = useState(''),
        [submitting,setSubmitting] = useState(false),
        [file,setFile] = useState(null),
        [isLocalIcon,setIsLocalIcon] = useState(false),
        fileInputRef = useRef()

    useEffect(()=>{
        if(props.state.isLocalIcon){
            navigationIconGet(props.state.icon).then((file)=>{
                setFile(file)
            })
        }
    },[])

    useEffect(()=>{
        if(errorTip){
            setTimeout(()=>{
                setErrorTip('')
            },3000)
        }
    },[errorTip])

    const iconUrl = useMemo(()=>{
        if(file){
            return URL.createObjectURL(file)
        }else if(icon){
            return icon
        }else{
            return null
        }
    },[file, icon])

    const handleFileInputChange = (e)=>{
        setFile(e.target.files[0])
        setIsLocalIcon(true)
        setIsDefaultIcon(false)
    }

    const handleSubmit = (e) =>{
        setSubmitting(true)
        const navigationData = {
            ...props.state,
            name,
            icon,
            _id:nanoid(),
            isCustom:true
        }
        navigationData.url = /https?:\/\//.test(url)?url:'http://'+url

        if(isLocalIcon){
            uploadFile(file).then((res)=>{
                if(props.state.isLocalIcon){
                    navigationForage.removeItem(props.state._id).then(null)
                }
                if(res?.url) {
                    navigationData.icon = res.url
                    save()
                }else{
                    navigationForage.setItem(props.state._id,file).then(()=>{
                        navigationData.icon = props.state._id
                        navigationData.isLocalIcon = true
                        save()
                    })
                }
            })
        }else{
            save()
        }

        function save(){
            const list = [...props.navigation.list]
            const index =  list.findIndex(item=>item._id===props.state._id)
            list.splice(index,1,navigationData)
            props.changeNavigation({...props.navigation,list})
            props.onOk(e)
        }
    }

    const onFileInputClick = ()=>{
        fileInputRef.current.click()
    }

    const onIconMatch = ()=>{
        getNavigationIcons(url).then(data=>{
            if(data.length>0){
                setFile(null)
                setIsLocalIcon(false)
                setIsDefaultIcon(false)
                setIcon(data[0])
            }else{
                setErrorTip('没有匹配的图标')
            }
        })
    }

    const handleIconReset = ()=>{
        setFile(null)
        setIsLocalIcon(false)
        setIsDefaultIcon(true)
        setIcon(props.state.icon)
    }

    return <Translation>{t=>(
        <AntModal className='navigation-modify-modal'
                  title={t('bookmark.modifyNavigationBookmarks')}
                  visible={true}
                  okText={t('word.confirm')}
                  cancelText={t('word.cancel')}
                  confirmLoading={submitting}
                  onOk={handleSubmit}
                  onCancel={props.onCancel} >
            <div className='navigation-modify-container'>

                <Form>
                    <Item label={t('word.name')} className='navigation-title'>
                        <Input className='name' defaultValue={name} onChange={e=>{setName(e.target.value)}}></Input>
                    </Item>

                    <Item label={t("word.link")} className='navigation-url'>
                        <Input className='url' defaultValue={url} onChange={e=>{setUrl(e.target.value)}}></Input>
                    </Item>

                    <Item label={t('word.icon')} className='navigation-icon'>
                        <div className='icon' style={{'--bg-color':props.state.bgColor || "#888"}}>
                            {
                                iconUrl? <img src={iconUrl} alt={name}/>:
                                    name.substring(0,1)
                            }
                            {
                                !isDefaultIcon?
                                <button onClick={()=>{handleIconReset()}}>{t('word.reset')}</button>:null
                            }
                        </div>

                        <button onClick={onFileInputClick} className='upload-button' type='link'>{t('bookmark.localUpload')}</button>

                        <button onClick={onIconMatch} className='match-button' type='link'>{t('bookmark.match')}</button>

                        <input className='upload-input' ref={fileInputRef} onChange={handleFileInputChange} type="file"/>

                    </Item>
                    {
                        errorTip?
                            <div className='error-tip'>{t(errorTip)}</div>:null
                    }
                </Form>
            </div>
        </AntModal>
    )}</Translation>
}

export default connect(
    state=>({
        navigation:state.navigation
    }),
    {
        changeNavigation:(data)=>({type:'changeNavigation',data})
    }
)(Edit)