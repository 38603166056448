import {backupUserData, getFavWallpapers, recoveryUserData} from "../api";

/**
 * 备份用户数据
 * @param state state数据
 * @param success 成功后的回调
 * @param error 失败后的回调
 */
export const backupData = ({state,success,error,mounted,unMounted} = {})=>{
    if(!state) return
    else if(typeof state==='function') state = state()
    if(typeof mounted==='function'){
        mounted()
    }
    const backupTime = Date.now()
    const data = {
        backup_time:backupTime,
        data:{
            navigation:state.navigation,
            wallpaper:{
                theme:state.wallpaper.theme,
                wallpaper:state.wallpaper.wallpaper,
                wallpapers: state.wallpaper.wallpapers
            },
            search:{
                current:state.search.current,
                searchEngineList:state.search.searchEngineList,
                searchEngineUse:state.search.searchEngineUse,
                theme:state.search.theme,
                setting:state.search[`setting${state.search.theme.substring(5)}`]
            },
            setting:state.setting
        }
    }
    backupUserData(data).then(res=>{
        if(res.code===10000){
            if(typeof success === 'function'){
                success({
                    backupTime,
                    rawData:res
                })
            }
        }else{
            if(typeof error === 'function'){
                error(res)
            }
        }
        if(typeof unMounted === "function"){
            unMounted()
        }
    })
}



/**
 * 用户数据恢复
 */
export const recoveryData = ({success,error,mounted,unMounted} = {})=>{
    if(typeof mounted === 'function'){
        mounted()
    }
    recoveryUserData().then(res=>{
        if(res.code===10000){
            const settingKey = `setting${res.data.search.theme.substring(5)}`;
            const data = {
                navigation:res.data.navigation,
                wallpaper:res.data.wallpaper,
                search:{
                    current:res.data.search.current,
                    searchEngineList:res.data.search.searchEngineList,
                    searchEngineUse:res.data.search.searchEngineUse,
                    theme:res.data.search.theme,
                    [settingKey]:res.data.search.setting
                },
                setting:res.data.setting,
                user:{
                    ...res.data.user,
                    currentDataTime:res.data.backup_time
                }
            }
            success(data)
        }else{
            if(typeof error === 'function'){
                error(res)
            }
        }
        if(typeof unMounted==='function'){
            unMounted()
        }
    })
}
