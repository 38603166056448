import React, {useCallback, useEffect, useMemo, useState} from "react";
import {connect} from 'react-redux'
import {message,Tooltip} from 'antd'
import {SearchOutlined, CaretDownOutlined} from '@ant-design/icons'
import {debounce} from "lodash";
import Color from 'color'
import {Translation} from 'react-i18next'
import {getBaiduSug as getBaiduSugApi, getSearchUrl as getSearchUrlApi, stats} from "../../api";
import AutoWord from "./autoword";
import SearchList from "./searchlist";
import SearchDrawer from "./drawer";
import devTool from "../../devTool";
import searchDefaultIcon from '../../assets/img/search_icon_default.png'
import {searchIconGet} from "../../plugins/localforage";
import LoadingIcon from '../../assets/img/loading.gif'
import './search.css'
import LogoGoogle from '../../assets/img/search_img_google.png'
import LogoBaidu from '../../assets/img/search_img_baidu.png'

const logos = {
    google:LogoGoogle,
    baidu:LogoBaidu,
}

const showIcon = true,
    timeout = 300


/**
 * 加载搜索引擎图标
 */
export const handleImgLoad = (callBack)=> {
    return (e) => {
        const target = e.target,
            src = target.dataset['src'],
            load = target.dataset['load'],
            oldSrc = target.getAttribute('src');
        if (oldSrc===src || load) return
        if (/^https?:\/\/|data:|^\//.test(src)) {
            target.setAttribute('src', src)
        } else {
            searchIconGet(src).then(file => {
                if (file) {
                    const url = URL.createObjectURL(file)
                    target.setAttribute('src', url)
                    target.dataset['load'] = '1'
                }
                if(typeof callBack==='function'){
                    callBack(target,file)
                }
            })
        }
    }
}

const calcBackgroundColor = (setting) =>{
    const {backgroundColor,bgOpacity} = setting,
     color = Color(backgroundColor).object()
    return  `rgba(${color.r},${color.g},${color.b},${typeof bgOpacity==='number'?bgOpacity:1})`
}

function Search(props){
    const [val,setVal] = useState(''),
        [aside,setAside] = useState(null),
        [autoList,setAutoList] = useState(null),
        [searchUrl,setSearchUrl] = useState(''),
        [showDrawer,setShowDrawer] = useState(false),
        current = useMemo(
            ()=>props.search.searchEngineList.find(item=>item.id===props.search.current),
            [props.search.current]
        )
    const setting = props.search[`setting${props.search.theme.slice(5)}`],
        style = {
            '--search-height':setting.height+'px',
            '--search-radius':setting.rounded * setting.height * 0.01 +'px',
            '--search-border-width':setting.border+'px',
            '--search-border-color':setting.borderColor,
            '--search-bg-color':calcBackgroundColor(setting),
            '--search-color':setting.color,
            '--search-top': '20vh',
        };

    useEffect(()=>{
        getSearchUrl().then(null)
    },[current])

    useEffect(()=>{
        if(showIcon){
            const img = document.querySelector('#search-box .search-icon');
            if(img){
                const src = img.dataset['src'],
                    url = img.src;
                if (/^https?:\/\/|data:|^\//.test(src)) {
                    img.setAttribute('src', src)
                } else {
                    searchIconGet(src).then(file => {
                        if (file) {
                            URL.revokeObjectURL(url)
                            const _url = URL.createObjectURL(file)
                            img.setAttribute('src', _url)
                            img.dataset['load'] = '1'
                        }
                    })
                }
            }
        }
    },[current])


    /**
     * 当按下回车时搜索
     */
    const handleKeyPress = (e)=>{
        if(e.key==='Enter'){
            handleSearch(e).then(null)
        }
    }

    /**
     * 处理键盘按钮抬起（获取搜索建议词）
     */
    const handleKeyUp = (e)=>{
        if(e.key!=='Enter'){
            if(val){
                getBaiduSug(e)
            }else{
                setAside(null)
            }
        }
    }

    /**
     * 搜索处理逻辑
     */
    const handleSearch = async (kw)=>{
        const isDev = props.search.isDev ?? false
        kw = typeof kw ==='string'?kw:val
        setVal('')
        if(isDev){
            if(kw==='***picknewtab***') {
                // 关闭开发者模式
                props.changeSearch({...props.search, isDev: undefined})
                return message.info('关闭开发者模式')
            }else{
                if(typeof devTool(kw) !== 'string'){
                    return
                }
            }
        }else if(kw==='***picknewtab***'){
            // 开启开发者模式
            props.changeSearch({...props.search,isDev:true})
            return message.warning('开启开发者模式')
        }
        let url = searchUrl.replace(/\{query\}/,kw)
        url = url.replace(/\{encoding\}/,'utf-8')
        props.incrementSearchHistory(kw)
        setTimeout(()=>{
            setAutoList(null)
            setAside('')
        },timeout+1)
        stats('search',{...props.system,msg:`${props.search.current},${kw}`,uid:props.user.id})
        window.open(url,props.search.blank===false?'_self':'_blank')
    }

    /**
     * 监听搜索引擎下拉点击（显示搜索引擎列表）
     */
    const onToggleSearch = (e)=>{
        e.stopPropagation()
        if(aside === 'search-list'){
            setAside('')
        }else{
            setAside('search-list')
        }
    }

    /**
     * 获取搜索推荐词
     */
    const getBaiduSug = useCallback(debounce((e)=>{
            if(e.target.value){
                getBaiduSugApi(e.target.value).then((data)=>{
                    if(data){
                        setAside('auto-word')
                        setAutoList(data)
                    }
                })
            }
        },timeout)
    ,[])

    /**
     * 获取搜索引擎链接（小尾巴）
     */
    const getSearchUrl = async ()=>{
        const url = await getSearchUrlApi(current.href)
        setSearchUrl(url||current.href)
    }

    /**
     * 处理搜索框聚焦
     */
    const handleFocus = ()=>{
        props.toggleWallpaperMask()
    }

    /**
     * 处理搜索框失焦
     */
    const handleBlur = ()=>{
        props.toggleWallpaperMask()
        // setAside('')
    }

    return <Translation>
        {
            t=>(
                <div id='search' style={style} >
                    {/*<div id='search-logo' style={{opacity:props.search.visibleLogo?'1':'0'}}>*/}
                    {/*    <img className='search-icon'*/}
                    {/*         data-src={logos[current.id] || current.img || searchDefaultIcon}*/}
                    {/*         onLoad={handleImgLoad()}*/}
                    {/*         alt="logo"/>*/}
                    {/*</div>*/}
                    <div id='search-box' className={props.search.theme ?? ''}>
                        {
                            props.search.theme==='theme1'?
                            <div onClick={onToggleSearch}>
                                <img className='search-icon'
                                     data-src={current.img || searchDefaultIcon}
                                     src={LoadingIcon}
                                     onLoad={handleImgLoad()}
                                     alt="logo"/>
                                <CaretDownOutlined />
                            </div>:
                            <Tooltip placement='top' title={t(current.name)}>
                                <SearchOutlined className='search-btn'/>
                            </Tooltip>
                        }
                        {/* pending 检查输入框文字排版方向 */}
                        <input type="text"
                               placeholder={t('word.search')}
                               value={val}
                               onChange={(e)=>{setVal(e.target.value)}}
                               onKeyPress={handleKeyPress}
                               onFocus={handleFocus}
                               onBlur={handleBlur}
                               onKeyUp={handleKeyUp}/>
                        {
                            props.search.theme==='theme1' ?
                                <SearchOutlined className='search-btn' onClick={handleSearch}/>:null
                        }
                        {
                            props.search.theme==='theme3' ?
                                <span
                                    onClick={handleSearch}
                                    style={{
                                        padding:'0 1rem',
                                        background:'#19776d',
                                        height:setting.height+'px',
                                        lineHeight:setting.height+'px',
                                        color:'#fff',
                                        cursor:'pointer'
                                    }}
                                >{t('word.search')}</span>:
                            null
                        }
                    </div>
                    {
                        aside==='auto-word'?
                            <AutoWord
                                onClickLi={handleSearch}
                                data={autoList}
                                onClose={()=>{setAside(null)}}/>
                            :null
                    }
                    {
                        aside==='search-list'?
                            <SearchList
                                onClickLi={(id)=>{props.changeSearch({...props.search,current:id})}}
                                onShowDrawer={()=>{ setShowDrawer(true) }}
                                onClose={()=>{ setAside(null) }}/>
                            :null
                    }
                    {
                        showDrawer?<SearchDrawer onClose={()=>{setShowDrawer(false)}} />:null
                    }
                </div>
            )
        }
    </Translation>
}

export default connect(
    (state)=>({
        user:state.user,
        search:state.search,
        system:state.system
    }),{
        toggleWallpaperMask:()=>({type:'toggleWallpaperMask'}),
        changeSearch:(data)=>({type:'changeSearch',data}),
        incrementSearchHistory:(data)=>({type:'incrementSearchHistory',data})
    }
)(Search)

