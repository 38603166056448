import {connect} from 'react-redux'
import {useMemo, useState} from "react";
import WindmillImage from '../../../assets/img/windmill.png'

const timeout = 500

function Windmill(props){
    const [toggling,setToggling] = useState(null)

    const rotating = useMemo(()=>{
        return toggling || props.wallpaper.loading
    },[toggling,props.wallpaper.loading])

    /**
     * 壁纸切换
     */
    const handleToggleWallpaper = ()=>{
        setToggling(true)
        const storage = {...props.wallpaper}
        let index = 0
        if(props.wallpaper.isFavWallpaper){
            if(props.user.favWallpapers.length>0){
                index = props.user.favWallpapers.indexOf(props.wallpaper.wallpaper)
                index = index>=props.user.favWallpapers.length-1?0:index+1
                storage.wallpaper = props.user.favWallpapers[index]
            }else{
                storage.wallpaper = storage.wallpapers[index]
                storage.isFavWallpaper = false
            }
        }else{
            index = props.wallpaper.wallpapers.indexOf(props.wallpaper.wallpaper)
            index = index>=props.wallpaper.wallpapers.length-1?0:index+1
            storage.wallpaper = storage.wallpapers[index]
        }
        storage.loading = true
        props.changeWallpaper(storage)
        setTimeout(()=>{
            setToggling(null)
        },timeout)
    }

    return props.wallpaper.wallpapers?
        <div id='windmill' className={rotating?'rotating':''}>
            <img src={WindmillImage} alt="windmill" onClick={handleToggleWallpaper}/>
        </div>:
        null
}

export default connect(
    state=>({
        wallpaper:state.wallpaper,
        user:state.user
    }),
    {
        changeWallpaper:data=>({type:'changeWallpaperStorage',data})
    }
)(Windmill)