import React from "react";
import {IframeClick} from '../../components'

export default class AutoWord extends React.Component {

    componentDidMount() {
        window.addEventListener('click',this.handleWindowClick)
    }

    componentWillUnmount() {
        window.removeEventListener('click',this.handleWindowClick)
    }

    handleClose = () => {
        window.removeEventListener('click',this.handleWindowClick)
        this.props.onClose()
        this.setState({close:true})
    }

    handleWindowClick = (e)=>{
        const dom = document.querySelector('#auto-word')
        if(dom?.contains && !dom.contains(e.target)){
            this.handleClose()
        }
    }

    handleIframeClick = (data) =>{
        if(data) {
            this.handleClose()
        }
    }

    handleClick = (word) =>{
        return (e)=>{
            this.props.onClickLi(word)
            this.handleClose()
        }
    }

    renderContent = (data)=>{
        if(data?.g?.length>0) {
            return <ul id='auto-word'>
                {
                    data.g.slice(0,5).map(item => (
                        <li onClick={this.handleClick(item.q)} key={item.sa}>{item.q}</li>
                    ))
                }
                {
                    <IframeClick>{this.handleIframeClick}</IframeClick>
                }
            </ul>
        }else{
            return null
        }
    }

    render() {
        const data = this.props.data
        return this.renderContent(data)
    }
}