import './App.css';
import React from 'react'
import Wallpaper from "./pages/wallpaper";
import Search from "./pages/search";
import Desktop from './pages/desktop'
import Navigation from './pages/navigation/index'
import Setting from "./pages/setting";
import Home from "./pages/home";
import './locales'

function App(){
    return (
        <>
            <Home/>
            <Wallpaper/>
            <Search/>
            <Navigation/>
            <Desktop/>
            <Setting />
        </>
    );
}

export default App;
