import {useMemo, useState} from 'react'
import {Select, Switch, Tooltip, Tag, Space, Spin, Modal, message, Radio, Button} from 'antd'
import {DownOutlined, LoginOutlined, UserOutlined, CloudDownloadOutlined, CloudUploadOutlined, LoadingOutlined} from '@ant-design/icons'
import {connect} from 'react-redux'
import {Translation,useTranslation} from 'react-i18next'
import qs from 'qs'
import LoginForm from "./LoginForm";
import DefaultAvatar from '../../assets/img/avatar.png'
import {checkBackupDataIsSame, getFavWallpapers} from "../../api";
import {backupData, recoveryData,} from "../../utils";
import {navigationIconReset} from "../../plugins/localforage";
import daShangQr from '../../assets/img/zhanshangma.jpeg'

const {Option} = Select

/**
 * 计算合并后并返回本地与云端导航书签
 */
function calcNavigation(cloud,local){
    const obj = {},
        arr = [],
        navigation = [...cloud.list,...local.list]
    navigation.forEach(item=>{
        if(!obj[item._id]){
            obj[item._id] = 1
            arr.push(item)
        }
    })
    return {...cloud,list:arr}
}

function System(props){
    const [activeVisible,setActiveVisible] = useState(!props.user.nickname),
        [activeOther,setActiveOther] = useState(false),
        [showLoginForm,setShowLoginForm] = useState(false),
        [modal,setModal] = useState(''),
        [backupIng,setBackupIng] = useState(false),
        [recovering,setRecovering] = useState(false),
        [mergeOption,setMergeOption] = useState('0'),
        [tip,setTip] = useState(null),
        setting = props.setting,
        user = props.user,
        {i18n} = useTranslation()

    const visible = [
        {key:'bookmark',value:setting.visible.bookmark,t:'word.bookmark'},
        {key:'windmill',value:setting.visible.windmill,t:'word.windmill'},
        {key:'wallpaperMask',value:setting.visible.wallpaperMask,t:'word.mask'},
        {key:'wallpaperList',value:setting.visible.wallpaperList,t:'setting.wallpaperButton'},
        {key:'weather',value:setting.visible.weather,t:'word.weather'},
        {key:'moreTheme',value:setting.visible.moreTheme,t:'setting.moreTheme'},
    ]

    /**
     * 计算意见反馈链接
     */
    const feedbackUrl = useMemo(()=>{
        const website = 'https://www.picknewtab.com'
        return `${website}/help/feedback?${qs.stringify(props.system)}`
    },[])

    /**
     * 处理显示/隐藏数据修改
     */
    const handleChangeVisible = (name)=>{
        return (val)=>{
            const setting = props.setting,
                visible = setting.visible
            visible[name] = val
            props.changeSetting({...setting,visible})
        }
    }

    /**
     * 数据备份
     */
    const handleBackup = (e)=>{
        if(backupIng)return
        setBackupIng(true)
        backupData({
            state:props.state,
            success(data){
                props.changeUser({...props.user,currentDataTime:data.backupTime})
                if(e?.message==='backup'){
                    setTip({type:'success',t:'tip.syncSuccess'})
                }else{
                    setTip({type:'success',t:'tip.backupSuccess'})
                }
            },
            error(res){
                if(res.code===20007){
                    props.changeUser({...props.user,token:undefined,nickname:undefined,avatar:undefined})
                    message.warning('用户登录已过期，请重新登录!')
                }else{
                    setTip({type:'error',message:res.msg})
                }
            },
            unMounted(){
                setBackupIng(false)
                setTimeout(()=>{
                    setTip(null)
                },2000)
            }
        })
    }

    /**
     * 用户数据恢复
     */
    const handleRecovery = (e)=>{
        if(recovering)return
        setRecovering(true)
        recoveryData({
            success(data){
                const merge = e?.message === 'merge'
                if(merge){
                    data.navigation =  calcNavigation(data.navigation,props.navigation)
                }
                changeStorageData(data)
                props.changeUser({...props.user,...data.user,favWallpapers:data.favWallpapers || props.user.favWallpapers})
                if(e?.message==='recovery' || merge){
                    setTip({type:'success',t:'tip.syncSuccess'})
                }else{
                    setTip({type:'success',t:'tip.recoverySuccess'})
                }
            },
            error(res){
                if(res.code===20004 || res.code===20007){
                    props.logout()
                    // props.changeUser({...props.user,token:undefined,nickname:undefined,avatar:undefined})
                    message.warning('用户登录已过期，请重新登录!')
                }else if(res.code===10004){
                    setTip({type:'error',t:"tip.cloudNotRecoveryData"})
                }else{
                    setTip({type:'error',message:res.msg})
                }
            },
            unMounted(){
                setRecovering(false)
                setModal('')
                setTimeout(()=>{
                    setTip(null)
                },2000)
            }
        })

        function changeStorageData(data){
            props.changeNavigation({...props.navigation,...data.navigation})
            props.changeWallpaper({...props.wallpaper,...data.wallpaper})
            props.changeSearch({...props.search,...data.search})
            i18n.changeLanguage(data.setting.language).then(()=>{
                props.changeSetting({...props.setting,...data.setting,language: data.setting.language})
            })
        }
    }


    /**
     * 清空数据
     */
    const handleResetData = ()=>{
        props.resetSearch()
        props.resetWallpaper()
        props.resetSetting()
        props.resetNavigation()
        navigationIconReset()
        props.logout()
        setModal('')
    }

    /**
     * 处理登录注册表单关闭
     */
    const handleLoginFormClose = (e)=>{
        setShowLoginForm(false)
        if(e?.message === 'logged in' && e?.token){
            checkBackupDataIsSame(props.user.currentDataTime, e.token).then((res)=>{
                if(res.code===10000){
                    if(typeof res.data === 'object'){
                        setModal('login')
                    }
                }else if(res.code===20007){
                    props.changeUser({...props.user,nickname:undefined,token:undefined,avatar:undefined})
                    message.warning('用户登录已过期，请重新登录!')
                }else if(res.code===40002){
                    return
                }else if(res.code===10002){
                    setModal('login')
                }else{
                    setTip({type:'error',message:res.msg})
                }
                setTimeout(()=>{
                    setTip(null)
                },2000)
            })
        }
    }

    /**
     * 监听数据合并点击
     */
    const onDataMerge = ()=>{
        switch (mergeOption){
            case '0':
                handleRecovery({message:'merge'})
                break
            case '1':
                handleRecovery({message:'recovery'})
                break
            case '2':
                getFavWallpapers().then(res=>{
                    if(res.code===10000){
                        props.changeUser({...props.user,favWallpapers:res.data})
                    }
                })
                setModal('')
                break
        }
    }

    /**
     * 监听modal确认按钮点击
     */
    const onModalOk = ()=>{
        if(modal==='login') {
            onDataMerge()
        }else if(modal==='logout'){
            backupData({
                state:props.state,
                unMounted(){
                    handleResetData()
                }
            })
        }
    }

    /**
     * 监听modal取消按钮点击
     */
    const onModalCancel = (e)=>{
        const html =  e.target.innerHTML,
            isCancelButton = /不清空|no\sempty/gi.test(html)
        if(modal==='logout' && isCancelButton ){
            backupData({
                state:props.state,
                unMounted(){
                    props.changeUser({...props.user,token:undefined,nickname:undefined,avatar:undefined})
                }
            })
        }
        setModal('')
    }

    /**
     * 监听登录点击
     */
    const onLogin = ()=>{
        setShowLoginForm(true)
    }

    /**
     * 监听注销点击
     */
    const onLogout = ()=>{
        setModal('logout')
    }

    return (
        <Translation>
            {t=> (
                <>
                    <div id='setting-system'>
                        <section id='user-box' className={user.nickname?'card logged':'not-logged'}>
                            {
                                user.nickname?
                                    <main>
                                        <div className='info'>
                                            <div className='avatar'>
                                                <img src={user.avatar||DefaultAvatar} alt=""/>
                                            </div>
                                            <div>
                                                <h3 title={user.nickname}>{user.nickname}</h3>
                                                <Space size={[4,4]} wrap>
                                                    <Tag className={recovering?'loading':''}
                                                         onClick={handleRecovery}
                                                         icon={<CloudDownloadOutlined />}
                                                         color="default">
                                                        {t('user.recovery')}
                                                        {
                                                            recovering?<Spin indicator={<LoadingOutlined/>} />:null
                                                        }
                                                    </Tag>
                                                    <Tag className={backupIng?'loading':''}
                                                         onClick={handleBackup}
                                                         icon={<CloudUploadOutlined />}
                                                         color="default">
                                                        {t('user.manualBackup')}
                                                        {
                                                            backupIng?<Spin indicator={<LoadingOutlined/>} />:null
                                                        }
                                                    </Tag>
                                                </Space>
                                                {
                                                    tip?<p className={`tip ${tip.type}`}>{tip.t?t(tip.t):tip.message}</p>:null
                                                }
                                            </div>
                                            <Tooltip placement='bottom' title={t('user.loginOut')}>
                                                <LoginOutlined onClick={onLogout} />
                                            </Tooltip>
                                        </div>

                                        <div className='switch'>
                                            {t('user.autoBackup')}
                                            <Switch checked={user.autoBackup}
                                                    onChange={(val)=>{props.changeUser({...user,autoBackup:val})}}/>
                                        </div>

                                        <div className='select' disabled={!user.autoBackup}>
                                            <span>{t('user.backupCycle')}</span>
                                            <Select defaultValue={user.backupCycle}
                                                    disabled={!user.autoBackup}
                                                    onChange={(val) => {
                                                        props.changeUser({...user, backupCycle: val})
                                                    }}>
                                                <Option value='0'>
                                                    {t('user.everyTime')}
                                                </Option>
                                                <Option value='1'>
                                                    {t('user.everyDay')}
                                                </Option>
                                                <Option value='2'>
                                                    {t('user.everyWeek')}
                                                </Option>
                                            </Select>
                                        </div>
                                    </main>:
                                    <main onClick={onLogin}>
                                        <button>
                                            <UserOutlined />
                                        </button>
                                        <div>
                                            <h3>{t('word.login')}/{t('word.register')}</h3>
                                            <p>{t('tip.loggedMoreFeatures')}</p>
                                        </div>
                                    </main>
                            }
                        </section>

                        <div className={`card visible ${activeVisible?'':'mini'}`}>
                            <header onClick={()=>{setActiveVisible(!activeVisible)}}>
                                {t('word.visible')}/{t('word.hidden')} <DownOutlined />
                            </header>
                            {
                                activeVisible?
                                <main>
                                    <div className='switch'>
                                        <Switch defaultChecked={props.search.visibleLogo !== false}
                                                onChange={(value) => {
                                                    props.changeSearch({...props.search, visibleLogo: value})
                                                }}/>{t('setting.visibleSearchLogo')}
                                    </div>
                                    {(() => {
                                        return visible.map((item) => {
                                            return (
                                                <div className='switch' key={item.key}>
                                                    <Switch checked={item.value}
                                                            onChange={handleChangeVisible(item.key)}/>
                                                    {t(item.t)}
                                                </div>
                                            )
                                        })
                                    })()}
                                </main>:null
                            }
                        </div>

                        <div className={`card other ${activeOther?'':'mini'}`}>
                            <header onClick={()=>{setActiveOther(!activeOther)}}>
                                {t('word.other')}<DownOutlined />
                            </header>
                            {
                                activeOther?
                                <main>
                                    <div className='select'>
                                        <span>{t('word.language')}</span>
                                        <Select value={setting.language}
                                                onChange={(val) => {
                                                    i18n.changeLanguage(val).then(()=>{
                                                        props.changeSetting({...setting, language: val})
                                                    })
                                                }}>
                                            <Option value='zh'>
                                                中文
                                            </Option>
                                            <Option value='en'>
                                                English
                                            </Option>
                                        </Select>
                                    </div>

                                    <Space className='btn-group' style={{marginTop:'0.5rem',marginBottom:'0.5rem'}}>
                                        <Button href='#giveMoney'>
                                            打赏
                                        </Button>

                                        <Button href={feedbackUrl} target='_blank'>
                                            {t('word.feedback')}
                                        </Button>
                                        {
                                            window.chrome.runtime.id !== "browser"?
                                                <Button style={{display:'none'}} target='_blank'
                                                        href={`https://microsoftedge.microsoft.com/addons/detail/${window.chrome.runtime.id}`}>
                                                    {t('setting.giveStar')}
                                                </Button>:null
                                        }
                                    </Space>

                                    <div id='giveMoney' className='giveMoney'>
                                        <img src={daShangQr} alt="打赏"/>
                                    </div>

                                </main>:null
                            }
                        </div>
                    </div>
                    {
                        showLoginForm?
                            <LoginForm className={'absolute'} onClose={handleLoginFormClose} />:null
                    }
                    <Modal zIndex={1002}
                           visible={modal !== ''}
                           mask={false}
                           maskClosable={false}
                           closable={modal!=='login'}
                           cancelText={t(modal==='logout'?'setting.notEmpty':'word.cancel')}
                           okText={t(modal==='logout'?'setting.empty':'word.confirm')}
                           onOk={onModalOk}
                           onCancel={onModalCancel}>
                        {
                            modal==='login'?
                                <div>
                                    <h3>{t('tip.mergeDataOption')}</h3>
                                    <Radio.Group onChange={(e)=>{setMergeOption(e.target.value)}} value={mergeOption}>
                                        <Space direction="vertical">
                                            <Radio value='0'>{t('tip.mergeData')}</Radio>
                                            <Radio value='1'>{t('tip.useCloudData')}</Radio>
                                            <Radio value='2'>{t('tip.useLocalData')}</Radio>
                                        </Space>
                                    </Radio.Group>
                                </div>:null
                        }
                        {
                            modal==='logout'?
                                <div>
                                    <h3>{t('user.clearData')}?</h3>
                                </div>:null
                        }
                    </Modal>
                </>
            )}
        </Translation>
    )
}

export default connect(
    (state)=>({
        state:state,
        navigation:state.navigation,
        search:state.search,
        wallpaper:state.wallpaper,
        user:state.user,
        setting:state.setting,
        system:state.system
    }),
    {
        logout:()=>({type:'logout'}),
        changeSearch:data =>({type:'changeSearch',data}),
        changeUser:data =>({type:'changeUser',data}),
        changeSetting: data => ({type: 'changeSetting', data}),
        changeWallpaper: data => ({type: 'changeWallpaperStorage', data}),
        changeNavigation: data => ({type: 'changeNavigation', data}),
        resetSearch:()=>({type:'reset'}),
        resetNavigation:()=>({type:'resetNavigation'}),
        resetWallpaper:()=>({type:'resetWallpaper'}),
        resetSetting:()=>({type:'resetSetting'}),
    }
)(System)