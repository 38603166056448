import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {connect} from 'react-redux'
import {Translation} from "react-i18next";
import {nanoid} from 'nanoid'
import {Button, Form, Input, Space,} from "antd";
import {PlusOutlined, LinkOutlined} from "@ant-design/icons";
import {useDebounce} from "../../utils";
import {getNavigationIcons, getNavigationTitle, uploadFile} from "../../api";
import {incrementNavigationAsync} from "../../redux/actions";
import {navigationForage} from "../../plugins/localforage";

const {Item,useForm} = Form,
    colors = ["#78c5d3","#469aa6","#78c266","#c4d847","#f4d63f","#f4d73d","#f38b31","#e66c9f","#bf62a6"];

function Custom(props){
    const [form] = useForm(),
        [link,setLink] = useState(""),
        [name,setName] = useState(""),
        [showFormMore,setShowFormMore] = useState(false),  // 是否显示完整表单
        [bgColor,setBgColor] = useState(''), // 当前背景颜色
        [icon,setIcon] = useState(null),  // 图标链接或图标文件
        [icons,setIcons] = useState([]), // 所有图标（推荐图标）
        [localIcons,setLocalIcons] = useState([]), // [上传的图片文件],当前使用的图片的索引
        [uploadLoading,setUploadLoading] = useState(false), // 上传中标记
        [fileValidateStatus,setFileValidateStatus] = useState('success'),
        [fileHelp,setFileHelp] = useState(''),
        uploadRef = useRef(),  // 文件上传input输入框ref
        colorInputRef = useRef();  // 文件上传input输入框ref

    const isLocalIcon = useMemo(()=>{
        return icon && !(typeof icon ==='string' && /https?:\/\//.test(icon))
    },[icon])

    const linkUrl = useMemo(()=>{
        return /^https?:\/\//.test(link)?link:'http://'+link
    },[link])

    useEffect(()=>{
        if(showFormMore){
            const url = form.getFieldValue('link')
            getNavigationIcons(url).then((icons)=>{
                if(icons && icons.length>0){
                    setIcon(icons[0])
                    setIcons(icons)
                }else{
                    setIcons([])
                    setBgColor('#f50')
                }
            })
        }
    },[showFormMore])

    /**
     * 根据网址获取网站标题
     */
    const getTitle = useCallback(useDebounce( async ()=>{
        const url = form.getFieldValue('link')
        const data = await getNavigationTitle(url) || url
        form.setFieldsValue({title:data})
        setName(data)
    },300),[])

    /**
     * 进入下一步处理逻辑，显示完整表单
     */
    const handleNext = ()=>{
        form.validateFields().then( ()=>{
            setShowFormMore(true)
        } )
    }

    /**
     * 文件上传处理逻辑
     */
    const handleUpload = (name)=>{
        return (e)=>{
            setFileValidateStatus('success')
            setFileHelp('')
            let file
            if(name ==='change'){
                file = e.target.files[0]
            }else if(name==='drop'){
                e.stopPropagation()
                e.preventDefault()
                file = e.dataTransfer.files[0];
            }
            if(!file) return
            if(handleFileValidator(file)){
                setIcon(file)
                setLocalIcons([file,...localIcons])
                setBgColor(null)
            }
        }
    }

    /**
     * 文件验证（格式、大小）
     */
    const handleFileValidator = (file)=>{
        let status = 'error',help = ''

        if(!/^image\//i.test(file.type)){
            help = '文件格式不正确。'
        }else if(file.size>1000*1000*2){
            help = '图片不能超过2M'
        }else{
            status = 'success'
        }
        setFileValidateStatus(status)
        setFileHelp(help)
        return status !== 'error'
    }

    /**
     * 表单提交
     */
    const handleSubmit = (e)=>{
        setUploadLoading(true)
        const bookmarkObj = {
            _id:nanoid(),
            url:linkUrl,
            name,
            isCustom:true,
        }

        if(isLocalIcon){
            uploadFile(icon).then((res)=>{
                if(res?.url) {
                    bookmarkObj.icon = res.url
                    save(bookmarkObj)
                }else{
                    bookmarkObj.icon = bookmarkObj._id
                    navigationForage.setItem(bookmarkObj._id,icon).then(()=>{
                        save(bookmarkObj)
                    })
                }
            })
        }else if(icon){
            bookmarkObj.icon = icon
            save(bookmarkObj,'icon')
        }else{
            bookmarkObj.bgColor = bgColor
            save(bookmarkObj,'bgColor')
        }
        function save(obj,m){
            setUploadLoading(false)
            props.incrementNavigation(obj)
            form.resetFields()
            setBgColor('')
            setShowFormMore(false)
            setLocalIcons([])
        }
    }

    const handleLinkChange = (e)=>{
        setLink(e.target.value)
        getTitle()
    }

    const handleNameChange = (e)=>{
        setName(e.target.value)
    }

    const handleColorChange = (e)=>{
        setBgColor(e.target.value)
    }

    const onColorInputClick = (e)=>{
        colorInputRef.current.click()
    }

    return (
        <Translation>
            {t=>(
                <Form form={form} layout='vertical'  >
                    {
                        showFormMore?
                            <Item label={t('word.preview')} >
                                <div className='preview'>
                                    <div className='icon' style={{background:bgColor}}>
                                        {
                                            (()=>{
                                                if(icon && !bgColor){
                                                    return <img src={(()=>{
                                                        return isLocalIcon?URL.createObjectURL(icon):icon
                                                    })()}
                                                                alt={form.getFieldValue('title')}/>
                                                }else{
                                                    return <span >{form.getFieldValue('title').substring(0,1)}</span>
                                                }
                                            })()
                                        }
                                    </div>
                                    {
                                        <span>{form.getFieldValue('title')}</span>
                                    }
                                </div>
                            </Item>:
                            <>
                                <Item label={(
                                    <>
                                        {t('word.link')}
                                        {
                                            form.getFieldValue('link')?
                                                <Button
                                                    icon={<LinkOutlined />}
                                                    href={ linkUrl }
                                                    type='link'
                                                    target='_blank'
                                                />:null
                                        }
                                    </>
                                )}
                                      name='link' required={true} rules={[{required:true,message:t('tip.linkRequired')}]}>
                                    <Input
                                        placeholder='https://www.picknewtab.com'
                                        value={link}
                                        onChange={handleLinkChange}
                                        onPressEnter={()=>{
                                            if(!showFormMore){
                                                handleNext()
                                            }
                                        }}
                                        disabled={showFormMore}
                                    />
                                </Item>
                                <Item label={t('word.name')} name='title' rules={[{ required: true, message: t('tip.nameRequired') }]}>
                                    <Input
                                        value={name}
                                        placeholder='picknewtab'
                                        onChange={handleNameChange}
                                        onPressEnter={(e)=>{
                                            e.stopPropagation()
                                            if(!showFormMore){
                                                handleNext()
                                            }
                                        }}
                                    />
                                </Item>
                            </>
                    }
                    {
                        showFormMore?
                            <>
                                <Item label={t('word.icon')}
                                      validateStatus={fileValidateStatus}
                                      help={t(fileHelp)}
                                >
                                    <div className='icon-list'>
                                        {icons.map(iconUrl=>(
                                            <div className={`icon ${icon===iconUrl?'active':''}`}
                                                 key={iconUrl}
                                                 onClick={()=>{
                                                     setIcon(iconUrl)
                                                     setBgColor(null);
                                                 }}>
                                                <img src={iconUrl} alt={form.getFieldValue('name')}/>
                                            </div>
                                        ))}

                                        {
                                            localIcons.map((iconFile,i)=>{
                                                return (
                                                    <div className={`icon ${iconFile===icon?'active':''}`}
                                                         key={i}
                                                         onClick={()=>{
                                                             setIcon(iconFile)
                                                             setBgColor(null)
                                                         }}>
                                                        <img src={(()=>{
                                                            return URL.createObjectURL(iconFile)
                                                        })()} alt={form.getFieldValue('name')}/>
                                                    </div>
                                                )
                                            })
                                        }

                                        <div className='upload-button icon'
                                             draggable={true}
                                             onClick={()=>{
                                                 uploadRef.current.click()
                                             }}
                                             onDrop={handleUpload('drop')}
                                             onDragEnter={(e)=>{
                                                 e.stopPropagation()
                                                 e.preventDefault()
                                             }}
                                             onDragOver={(e)=>{
                                                 e.stopPropagation()
                                                 e.preventDefault()
                                             }}
                                             style={{display:'flex',flexDirection:'column'}}>
                                            <PlusOutlined />
                                            <div>{t('word.upload')}</div>
                                        </div>
                                        <input style={{display:'none'}}
                                               accept='image/*'
                                               type="file" ref={uploadRef} onChange={handleUpload('change')}/>
                                    </div>
                                </Item>
                                <Item label={t('setting.backgroundColor')}>
                                    <ul className='color-list'>
                                        {
                                            colors.map(color=>{
                                                return <li style={{color:color}}
                                                           onClick={()=>{setBgColor(color);setIcon(null)}}
                                                           className={`${color===bgColor?'active':''}`}
                                                           key={color}
                                                />
                                            })
                                        }
                                        <li onClick={onColorInputClick}
                                            className={`${bgColor && !colors.includes(bgColor)?'active':''}`}
                                            style={{
                                                color:`${bgColor && !colors.includes(bgColor)?bgColor:""}`,
                                                background:'conic-gradient(red 0,yellow 25%,#3261e9 50%,pink 75%, red 100%)',
                                            }} />
                                        <input ref={colorInputRef}
                                               onChange={handleColorChange}
                                               type="color"
                                               style={{
                                                   visibility:"hidden",
                                                   position:'absolute',
                                                   right:0,
                                               }}/>
                                    </ul>
                                </Item>
                                <Item>
                                    <Space>
                                        <Button type='primary' onClick={()=>{
                                            setShowFormMore(false)
                                        }}>{t('word.previous')}</Button>
                                        <Button type='primary' onClick={handleSubmit}
                                                htmlType='submit' loading={uploadLoading}>{t('word.add')}</Button>
                                    </Space>
                                </Item>
                            </>:
                            <Item>
                                <Button type='primary' htmlType='submit' onClick={handleNext}>{t('word.next')}</Button>
                            </Item>
                    }
                </Form>
            )}
        </Translation>
    )
}

export default connect(
    null,
    {
        incrementNavigation:incrementNavigationAsync
    }
)(Custom)