import {useState} from 'react'
import {Radio, Space, Button, Select, Switch} from 'antd'
import { DownOutlined} from '@ant-design/icons'
import {connect} from "react-redux";
import {Slider} from "../../components";
import {Translation} from 'react-i18next'

function Search(props){
    const [activeTheme,setActiveTheme] = useState(true),
        [activeParams,setActiveParams] = useState(true),
        [activeOther,setActiveOther] = useState(false)

    const handleChangeTheme = (e)=>{
        const theme = e.target.value
        props.changeSearch({...props.search,theme})
    }

    const handleChange = (name)=>{
        return (e)=>{
            const value = e.target.value*1
            props.changeSearchSetting({key:name,value})
        }
    }

    const handleReset = ()=>{
        props.resetSearch()
    }

    const search = props.search,
        setting = search[`setting${search.theme.slice(5)}`]

    return <Translation>
        { t=>(
            <div id='setting-search'>
                <div className={`theme card ${activeTheme?'':'mini'}`}>
                    <header onClick={()=>{setActiveTheme(!activeTheme)}}>
                        {t('word.theme')}<DownOutlined />
                    </header>
                    {
                        activeTheme?
                        <main>
                            <Radio.Group onChange={handleChangeTheme} value={search.theme}>
                                <Space direction="vertical">
                                    <Radio value='theme1'>{t('setting.theme1')}</Radio>
                                    <Radio value='theme2'>{t('setting.theme2')}</Radio>
                                    <Radio value='theme3'>{t('setting.theme3')}</Radio>
                                </Space>
                            </Radio.Group>
                        </main>:null
                    }
                </div>

                <div className={`params card ${activeParams?'':'mini'}`}>
                    <header onClick={()=>{setActiveParams(!activeParams)}}>
                        {t('word.param')}<DownOutlined />
                    </header>
                    {
                        activeParams?
                        <main>
                            <div className='slider'>
                                <span>{t('word.height')}</span>
                                <Slider min={0} max={500}
                                        value={setting.top}
                                        onChange={handleChange('top')}/>
                                <span>{setting.top}px</span>
                            </div>
                            <div className='slider'>
                                <span>{t('word.length')}</span>
                                <Slider min={40} max={90} value={setting.width}
                                        onChange={handleChange('width')}/>
                                <span>{setting.width}%</span>
                            </div>
                            <div className='slider'>
                                <span>{t('word.width')}</span>
                                <Slider min={40} max={100} value={setting.height}
                                        onChange={handleChange('height')}/>
                                <span>{setting.height}px</span>
                            </div>
                            <div className='slider'>
                                <span>{t('word.radius')}</span>
                                <Slider min={0} max={50} value={setting.rounded}
                                        onChange={handleChange('rounded')}/>
                                <span>{setting.rounded}%</span>
                            </div>
                            <div className='slider'>
                                <span>{t('word.opacity')}</span>
                                <Slider min={0} max={1} step={0.05}
                                        value={setting.bgOpacity ?? 1}
                                        onChange={handleChange('bgOpacity')}/>
                                <span>{setting.bgOpacity ?? 1}</span>
                            </div>
                            <div className='slider'>
                                <span>{t('word.border')}</span>
                                <Slider min={0} max={10} value={setting.border}
                                        onChange={handleChange('border')}/>
                                <span>{setting.border}px</span>
                            </div>
                        </main>:null
                    }
                </div>

                <div className={`other card ${activeOther?'':'mini'}`}>
                    <header  onClick={()=>{setActiveOther(!activeOther)}}>
                        {t('word.other')}<DownOutlined />
                    </header>
                    {
                        activeOther?
                        <main>
                            <div className='switch'>
                                <Switch defaultChecked={search.blank !== false}
                                        onChange={(value) => {
                                            props.changeSearch({...search, blank: value})
                                        }}/>{t('setting.openNewWindowSearch')}
                            </div>

                            <div className='select'>
                                <span>{t('setting.searchEngine')}</span>
                                <Select value={search.current}
                                        onChange={(val) => {
                                            props.changeSearch({...search, current: val})
                                        }}>
                                    {(() => {
                                        const {Option} = Select
                                        return search.searchEngineList
                                            .filter(item => search.searchEngineUse.includes(item.id))
                                            .map((item) => {
                                                return (
                                                    <Option value={item.id} key={item.id}>
                                                        {item.name}
                                                    </Option>
                                                )
                                            })
                                    })()}
                                </Select>
                            </div>
                        </main>:null
                    }
                </div>

                <div style={{margin:'1rem'}}>
                    <Button className='simple-btn' type='primary'
                            onClick={handleReset}>{t('word.reset')}
                    </Button>
                </div>
            </div>
        )}
    </Translation>
}

export default connect(
    (state)=>({search:state.search}),
    {
        changeSearch: data => ({type: 'changeSearch', data}),
        changeSearchSetting: data => ({type: 'changeSearchSetting', data}),
        resetSearch: () => ({type: 'resetSearchSetting'}),
    }
)(Search)