import React, { useEffect, useRef, useState} from 'react'
import {connect} from 'react-redux'
import {PlusOutlined,CloseOutlined,FormOutlined,UpOutlined} from '@ant-design/icons'
import {Carousel} from 'antd'
import {ReactSortable} from 'react-sortablejs'
import {Translation} from 'react-i18next'
import NavigationLibrary from "./library";
import {IframeClick} from "../../components";
import {getDefaultNavigationAsync, incrementNavigationAsync} from '../../redux/actions'
import './navigation.css'
import LoadingImg from '../../assets/img/1px.png'
import {navigationForage, navigationIconGet} from "../../plugins/localforage";
import Edit from './edit'
import {addListener, connectContent} from "../../utils";


function Navigation(props){
    const storage = props.navigation,  // 导航数据
        setting = props.setting, // 设置数据
        [editing,setEditing] = useState(false), // 编辑中标记
        [listener,setListener] = useState(false), // chrome事件监听标记
        [editModal,setEditModal] = useState(null),  // 书签编辑Modal数据
        [currentPage,setCurrentPage] = useState(0),  // 当前页面
        [showDrawer,setShowDrawer] = useState(false),  // 显示/隐藏书签库标记
        [lists,setLists] = useState([]),  // 书签列表（分页后）
        [carouselGoing,setCarouselGoing] = useState(false), // 标记carousel是否在切换中（切换动画延迟）
        [dragging,setDragging]= useState(false), // 标记是否正在拖拽中
        [baseSize,setBaseSize]= useState(document.body.clientWidth/7), // 设置书签基础大小(计算方式为屏幕宽度的1/20)
        carousel = useRef(), // 轮播Ref
        size = storage.size*0.01*baseSize,
        spaceX = storage.spaceX*0.01*size,
        spaceY = storage.spaceY*0.01*size,
        fontSize = size*(1/6)<12?12:size*(1/6),
        style = {
            '--navigation-width': ((size + spaceX) * 4) + 'px',
            '--navigation-height': ((size + spaceY + fontSize*2) * storage.rows) + 'px',
            '--navigation-rounded':storage.radius +'%',
            '--navigation-size':size + 'px',
            '--navigation-space-x':spaceX+'px',
            '--navigation-space-y':spaceY+'px',
            '--navigation-font-size':fontSize+'px',
            '--navigation-opacity':storage.opacity,
        };
    useEffect(()=>{
        window.addEventListener('resize',(e)=>{
            setBaseSize(document.body.clientWidth/7)
        })
        if(!listener){
            connectContent('navigation-install',()=>{
                setListener(true)
                addListener('navigation-install',async (request,sender,sendResponse)=>{
                    props.refreshNavigation()
                    sendResponse('ok')
                })
            })
        }
    },[])

    useEffect(()=>{
        carousel.current?.goTo(currentPage)
    },[currentPage])

    useEffect(()=>{
        if(storage.adding){
            setCurrentPage(lists.length-1)
            props.changeNavigation({...props.navigation,adding:undefined})
        }
    },[storage.adding])

    useEffect( ()=>{
        if(storage.list===null){
            props.getDefaultNavigation()
        }
        calcNavigationSplit()
    },[storage.rows,storage.list])

    /**
     * 书签分页计算，计算每页书签元素
     */
    const calcNavigationSplit= (list)=>{
        if(!storage.list || storage.list.length===0){
            setLists([])
            return
        }
        const {rows} = storage,
            pCount=rows * 4
        list = list ?? [...storage.list]
        const arr = []
        while(list.length>0) {
            arr.push(list.splice(0, pCount))
        }
        if(pCount!==0&&arr[arr.length-1].length===pCount){
            arr.push([])
        }
        setLists(arr)
    }

    /**
     * iframe点击监听处理
     */
    const handleIframeClick = (data)=>{
        if(data){
            setEditing(false)
        }
    }

    /**
     * 书签编辑保存
     */
    const handleSave = (e)=>{
        e.stopPropagation()
        setEditModal(null)
        calcNavigationSplit()
    }

    /**
     * 书签编辑取消保存
     */
    const handleCancelSave = (e)=>{
        e.stopPropagation()
        setEditModal(null)
    }

    /**
     * 监听书签拖拽，当拖拽到分页小圆点时手动翻页。
     */
    const handleDragStart = (e)=>{
        setDragging(true)
        window.addEventListener('drag',handleDrag)
    }

    const handleDragEnd = ()=>{
        setDragging(false)
        window.removeEventListener('drag',handleDrag)
    }

    const handleDrag = (e)=>{
        calcOnSlickDots(e)
    }

    /**
     * 计算鼠标位置，是否在分页小圆点上。
     */
    const calcOnSlickDots = (e)=>{
        if(carouselGoing) return
        const mouseX = e.clientX,
            mouseY = e.clientY
        const rects = calcSlickDotsArea()
        rects.forEach((rect,i)=>{
            if(mouseX>rect.left && mouseX<rect.right && mouseY>rect.top && mouseY<rect.bottom){
                if(i===rects.length-1){
                    carousel.current.next()
                }else if(i===rects.length-2){
                    carousel.current.prev()
                }else{
                    setCurrentPage(i)
                }
            }
        })
    }

    /**
     * 计算并分页小圆点位置
     */
    const calcSlickDotsArea = ()=>{
        const lis = document.querySelectorAll('#navigation-box .slick-dots li,#drag-drop-container li')

        const arr = []
        lis.forEach(li=>{
            arr.push(li.getBoundingClientRect())
        })
        return arr
    }

    /**
     * 拖拽组件ReactSortable的setList处理
     */
    const handleSetList = (dragPage)=>{
        return (val)=>{
            lists[dragPage] = val
            let list = []
            lists.forEach(_list=>{
                list = [...list,..._list]
            })
            props.changeNavigation({...storage,list})
        }
    }

    /**
     * 处理书签打开，当点击书签后触发。
     */
    const handleOpen = (item)=>{
        return () =>{
            const blank = storage['blank']
            if(editing){
                return
            }
            window.open(item.url,blank!==false?'_blank':'_self')
        }
    }

    /**
     * 书签删除处理
     */
    const handleDelete = (item)=>{
        return (e)=>{
            e.stopPropagation()
            if(item.isLocalIcon){
                navigationForage.removeItem(item.icon).then(()=>{
                    const list = storage.list.filter(li=>li._id!==item._id)
                    const store = {...storage,list}
                    props.changeNavigation(store)
                })
            }else{
                const list = storage.list.filter(li=>li._id!==item._id)
                const store = {...storage,list}
                props.changeNavigation(store)
            }
        }
    }

    /**
     * 处理鼠标右键单击，进入编辑模式
     */
    const handleContextMenu = (e)=>{
        e.preventDefault()
        setEditing(true)
        window.addEventListener('click',onCancelEdit)
    }

    /**
     * 处理鼠标滚轮滚动，实现书签翻页
     */
    const handleWheel = (e)=>{
        if(carouselGoing) return
        if(e.deltaY>0 || e.deltaX>0){  // up | left
            carousel.current.prev()
        }else if(e.deltaY<0 || e.deltaX<0){ // down | right
            carousel.current.next()
        }
    }

    /**
     * 取消编辑模式
     */
    const onCancelEdit = (e)=>{
        const navs = document.querySelectorAll('#navigation li');
        for(let i=0;i<navs.length;i++){
            if(navs[i].contains(e.target)){
                return
            }
        }
        setEditing(false)
        window.removeEventListener('click',onCancelEdit)
    }

    /**
     * 进入书签编辑模式，编辑书签的信息
     */
    const onEdit = (item)=>{
        return ()=>{
            setEditModal(item)
        }
    }

    /**
     * 显示远程书签库
     */
    const onAdd = ()=>{
        setShowDrawer(true)
    }

    /**
     * 导航图标加载
     */
    const handleImgLoad = (e) =>{
        const target = e.target,
            dataSrc = target.dataset['src'],
            src = target.getAttribute('src'),
            load = target.dataset['load']
        if(src===dataSrc || load) return
        if(/^https?:\/\//.test(dataSrc)){
                target.setAttribute('src',dataSrc)
        }else{
            navigationIconGet(dataSrc).then(file=>{
                if(file){
                    const url = URL.createObjectURL(file)
                    target.setAttribute('src',url)
                    target.dataset['load'] = '1'
                }else{
                    target.nextSibling.style.display = 'flex'
                    target.parentElement.removeChild(target)
                }
            })
        }
    }

    const handleToggleMini = ()=>{
        const navigationVisible = !setting.visible['bookmark']
        props.changeSetting({...setting,visible:{...setting.visible,bookmark:navigationVisible}})
    }

    return (<Translation>{t=>
        <div id='navigation' style={style}>
            <header className={setting.visible['bookmark']?'':'mini'}>
                <div onClick={handleToggleMini}>
                    { setting.visible['bookmark']? null: t('setting.quickLinks') }
                    <UpOutlined />
                </div>
            </header>
            {
                setting.visible['bookmark'] && storage.list ?
                    <div id='navigation-box' onWheel={handleWheel}>
                        <Carousel
                            ref={carousel}
                            initialSlide={currentPage}
                            autoplay={false}
                            dotPosition="bottom"
                            beforeChange={(from, to) => {
                            }}
                            afterChange={(i) => {
                                setCarouselGoing(true)
                                setCurrentPage(i)
                                setTimeout(() => {
                                    setCarouselGoing(false)
                                }, 500)
                            }}
                        >
                            {
                                lists.map((list, page) => (
                                    <div key={page}>
                                        <ReactSortable
                                            tag='ul'
                                            className='wrap draggable'
                                            group={{name: 'bookmark', clone: true}}
                                            list={list}
                                            draggable='.item'
                                            onStart={handleDragStart}
                                            onEnd={handleDragEnd}
                                            setList={handleSetList(page)}
                                        >
                                            {list.map(item => <li key={item._id} className='item'>
                                                <div className='icon'
                                                     onClick={handleOpen(item)}
                                                     onContextMenu={handleContextMenu}>
                                                    <img data-src={item.icon}
                                                         style={{display: item.icon ? 'unset' : 'none',}}
                                                         src={LoadingImg}
                                                         onLoad={handleImgLoad}
                                                         onMouseDown={(e) => {
                                                             e.stopPropagation()
                                                         }}
                                                         alt={item.name}/>
                                                    <div style={{
                                                        display: item.icon ? 'none' : 'flex',
                                                        background: item.bgColor || '#0005',
                                                        color: '#fff'
                                                    }}>
                                                        {item.name.substring(0, 1)}
                                                    </div>
                                                    {editing ?
                                                        <>
                                                            <CloseOutlined onClick={handleDelete(item)}/>
                                                            <FormOutlined onClick={onEdit(item)}/>
                                                        </>
                                                        : null
                                                    }
                                                </div>
                                                <span onClick={handleOpen(item)}
                                                      onContextMenu={handleContextMenu}>
                                                        {item.name}
                                                    </span>
                                            </li>)}
                                            {(storage.visibleAddButton && lists.length - 1 === page) ?
                                                <li>
                                                    <div className='icon' onClick={onAdd}>
                                                        <div><PlusOutlined/></div>
                                                    </div>
                                                    <span onClick={onAdd}>{t('bookmark.add')}</span>
                                                </li> :
                                                <></> // 这里不能用null必须要使用标签，原因不确定
                                            }
                                        </ReactSortable>
                                    </div>
                                ))
                            }
                        </Carousel>
                        {
                            (!storage.list || storage.list.length === 0) ?
                                <ul className='empty-navigation wrap'>
                                    <li>
                                        <div className='icon' onClick={onAdd}>
                                            <div><PlusOutlined/></div>
                                        </div>
                                        <span onClick={onAdd}>{t('bookmark.add')}</span>
                                    </li>
                                </ul>:null
                        }
                    </div> :
                    null
            }
            {
                // 拖拽放置位置
                dragging && lists.length>1 && !carouselGoing?
                <ul id='drag-drop-container'>
                    <li className='left'></li>
                    <li className='right'></li>
                </ul>:null
            }
            { editing?
                <IframeClick>{handleIframeClick}</IframeClick>:null
            }
            { editModal? <Edit onOk={handleSave} onCancel={handleCancelSave} state={editModal}/>:null }
            { showDrawer?
                <NavigationLibrary onClose={()=>{setShowDrawer(false)}} />
                :null
            }
        </div> }
    </Translation>)
}

export default connect(
    (state)=>({
        wallpaper:state.wallpaper,
        search:state.search,
        navigation:state.navigation,
        setting:state.setting
    }),
    {
        refreshNavigation: () => ({type: 'refreshNavigation'}),
        changeNavigation: data => ({type: 'changeNavigation', data}),
        changeNavigationPage:data => ({type:'changeNavigationPage',data}),
        getDefaultNavigation: getDefaultNavigationAsync,
        changeSetting: data => ({type: 'changeSetting', data}),
        toggleWallpaperMask:() => ({type:'toggleWallpaperMask'}),
        incrementNavigation:incrementNavigationAsync
    }
)(Navigation)