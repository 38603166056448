const searchEngineName = {
    "baidu":"baidu",
    "sougou":'sougou',
    "bing":"bing"
}
export default {
    ...searchEngineName,
    word:{
        // a
        add:'add',
        // b
        baidu:'baidu',
        bookmark:'bookmark',
        border:'border',
        // c
        cancel:'cancel',
        close:'close',
        custom:'custom',
        color:'color',
        columns:'columns',
        confirm:'confirm',
        // d
        delete:'delete',
        // e
        edit:'edit',
        example:'example',
        error:'error',
        // f
        feedback:'feedback',
        // g
        // h
        height:'height',
        hidden:'hidden',
        // i
        icon:'icon',
        // j
        // k
        // l
        language:'language',
        layout:'layout',
        length:'length',
        login:'login',
        link:'link',
        // m
        more:'more',
        mask:"mask",
        // n
        name:'name',
        navigation:'navigation',
        next:'next',
        // o
        opacity:'opacity',
        other:'other',
        // p
        param:'parameter',
        password:'password',
        preview:'preview',
        previous:'previous',
        // q
        // r
        radius:'radius',
        register:'register',
        remove:'remove',
        reset:'reset',
        rows:'rows',
        // s
        setting:'setting',
        search:'search',
        site:'site',
        size:'size',
        space:'space',
        spacing:'spacing',
        style:'style',
        submit:'submit',
        system:'system',
        // t
        test:'test',
        theme:'theme',
        time:'time',
        // u
        url:'url',
        use:'use',
        user:'user',
        username:'username',
        using:'using',
        upload:'upload',
        // v
        visible:'visible',
        // w
        wallpaper:'wallpaper',
        weather:'weather',
        width:'width',
        windmill:'windmill',
        // x
        // y
        // z
    },
    setting:{
        theme1:'theme 1',
        theme2:'theme 2',
        theme3:'theme 3',
        openNewWindowSearch:'Open search results from a new window',
        openNewWindowBookmark:'Open bookmark from a new window',
        searchEngine:'search engine',
        visibleMoreTheme:'Visible more theme',
        visibleAddButton:'Visible add button',
        visibleWallpaperMask:'Wallpaper mask',
        visibleWallpaperList:'Wallpaper list',
        visibleLogo:'Visible logo',
        visibleSearchLogo:'Search engine logo',
        wallpaperButton:'wallpaper button',
        moreTheme:"More theme",
        moreThemes:'more themes',
        moreWallpaper:'more wallpapers',
        clickVisible:'click open',
        bookmarkMinimized:'Navigation have been closed.',
        installTheme:'install theme',
        favTheme:'collection theme',
        favWallpaper:'collection wallpaper',
        cancelFav:'cancel collection',
        empty:'empty',
        notEmpty:'no empty',
        giveStar:'comment',
        backgroundColor:'background color',
        searchInput:'search',
        navigationLink:'navigation',
        wallpaperMask:'wallpaper mask',
        quickLinks:'quick links'
    },
    bookmark:{
        library:'library',
        add:'add',
        modifyNavigationBookmarks:"modify navigation bookmarks",
        localUpload:'upload',
        match:'match'
    },
    search:{
        custom:'custom',
        defaultInfo:"Custom search engine",
        deleteConfirm:'Are you sure you delete %s search engine',
        list:'list',
    },
    tip:{
        linkRequired:'Link cannot be empty.',
        linkNotQueryWord:"The link has no replaceable word '{query}'。",
        nameRequired:'Name cannot be empty.',
        usernameRequired:'Username cannot be empty.',
        passwordRequired:'Password cannot be empty.',
        confirmPasswordRequired:'Confirm password cannot be empty.',
        confirmPasswordDifferent:'The confirmation password is inconsistent with the password',
        linkOrNameRequired:'Link or Name cannot be empty.',
        usernameIsExist:'User name already exists.',
        passwordLength:'The password must be 6-20 characters.',
        usernameOrPasswordError:'Wrong user name or password.',
        linkUseHelp:'【Replace the search term with {query}】',
        loggedMoreFeatures:"Get more features after logging in",
        networkError:"network error",
        cloudNotRecoveryData:"There is no recoverable data in the cloud.",
        loginExpire:'User login expired.',
        recoverySuccess:"recovery success",
        backupSuccess:"backup success",
        syncSuccess:"synchronization succeeded",
        cloudAndLocalDataDifferent:"Cloud data is inconsistent with local data.",
        mergeDataOption:"Please select the data merge  method.",
        mergeData:"merge",
        useCloudData:"use cloud data",
        useLocalData:"use local data",
        removeWallpaper:"Are you sure to remove this wallpaper?",
        favWallpaperEmpty:"Favorite wallpaper is empty.",
        passwordTypeError:"Passwords cannot be pure numbers or letters"
    },
    wallpaper:{
        getMoreWallpaper:'Get more wallpapers',
        next:'next',
        prev:'prev',
        use:'use wallpaper',
        remove:'remove wallpaper',
        upload:'wallpaper upload',
        localUpload: "local upload",
        themeWallpaper:'theme',
        favorite:'favorite'
    },
    user:{
        autoBackup:'automatic backup',
        backupCycle:'backup cycle',
        confirmPassword:'confirm password',
        everyTime:'every time',
        everyDay:'every day',
        everyWeek:'every week',
        recovery:'data recovery',
        manualBackup:'manual backup',
        signOut:'sign out',
        loginOut:'login out',
        userLogin:'user login',
        userRegister:'user register',
        accountRegister:'account register',
        accountLogin:'account login',
        quickLogin:'quick login',
        qqQuickLogin:'login with QQ',
        clearData:"clear data"
    }
}