import {connect} from "react-redux";
import Background from "./Background";
import WallpaperList from "./List";
import { addListener,connectContent,queryParse } from '../../utils'
import './index.css'
import {useEffect, useState} from "react";
import {getThemeWithWallpaper, getWallpaper} from "../../api";

function Wallpaper(props){
    const [listener,setListener] = useState(false)

    useEffect(()=>{
        getWallpapers()
    },[])

    const getWallpapers = ()=>{
        const queryString = window.location.search.substring(1),
            queryObj =  queryParse(queryString),
            wallpaperId = queryObj.id || 333
        getWallpaper(wallpaperId).then(data=>{
            const wallpaperUrl = 'http:' + data.cover
            const wallpaper = {...props.wallpaper}
            wallpaper.wallpaper = wallpaperUrl
            const index = wallpaper.wallpapers.findIndex(wallpaper=>wallpaper===wallpaperUrl)
            if(index===-1){
                wallpaper.wallpapers = [wallpaperUrl,...wallpaper.wallpapers]
            }else{
                wallpaper.wallpapers = [wallpaperUrl, ...wallpaper.wallpapers.slice(0,index), ...wallpaper.wallpapers.slice(index+1)]
            }
            props.changeWallpaper(wallpaper)
        })
    }

    useEffect(()=>{
        /**
         * foreground安装监听
         */
        if(!listener){
            connectContent('install-wallpaper',()=>{
                setListener(true)
                addListener('install-wallpaper',async (request,sender,sendResponse)=>{
                    if (!request?.wallpaperUrl) {
                        sendResponse("壁纸丢失了");
                    } else if (props.wallpaper.wallpapers.includes(request.wallpaperUrl)) {
                        const wallpaper = {...props.wallpaper}
                        wallpaper.wallpaper = request.wallpaperUrl
                        wallpaper.wallpapers.splice(wallpaper.wallpapers.indexOf(request.wallpaperUrl),1)
                        wallpaper.wallpapers.unshift(request.wallpaperUrl)
                        props.changeWallpaper(wallpaper)
                        sendResponse('安装成功');
                    } else {
                        const wallpaper = {...props.wallpaper}
                        wallpaper.wallpaper = request.wallpaperUrl
                        wallpaper.wallpapers.unshift(request.wallpaperUrl)
                        props.changeWallpaper(wallpaper)
                        sendResponse('安装成功');
                    }
                })
            })
        }
    },[props.wallpaper])

    return  <>
        <div id='wallpaper' className={`wallpaper`}>
            {
                // isMac?
                    <Background />
                    // <Iframe />
            }
            { props.visibleMask || props.searchFocus?
                <div className={props.searchFocus?'mask focus':'mask'} />:null
            }
        </div>
        {
            props.visibleList?
                <WallpaperList />
                :null
        }
    </>
}

export default connect(
    (state)=>({
        wallpaper:state.wallpaper,
        searchFocus:state.wallpaper.visible.mask,
        visibleMask:state.setting.visible.wallpaperMask,
        visibleList:state.wallpaper.visible.list
    }),
    {
        changeWallpaper:(data)=>({type:'changeWallpaperStorage',data})
    }
)(Wallpaper)

