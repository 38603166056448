import {Tabs, Form, Input, Button, Spin} from "antd";
import {Translation} from "react-i18next";
import {connect} from "react-redux";
import {QqOutlined, CloseOutlined, LoadingOutlined} from '@ant-design/icons'
import {checkUsernameIsExist, userLogin, userRegister, qqLoginCheck} from "../../api";
import {useEffect, useState} from "react";
import {nanoid} from 'nanoid'
import {addListener, connectContent} from "../../utils";
import './LoginForm.css'

const {TabPane} = Tabs,
    {useForm} = Form
let qqLoginTimer

/**
 * 登录中加载loading
 */
const LoadingIcon = (props)=>{
    return <div {...props} className={`login-loading ${props.type || ""}`}>
            <LoadingOutlined />
            <Button onClick={props.onCancel}>取消登录</Button>
    </div>
}


function LoginForm(props){
    let [form] = useForm(),
        [passwordValidateStatus,setPasswordValidateStatus] = useState('success'),
        [confirmPasswordValidateStatus,setConfirmPasswordValidateStatus] = useState('success'),
        [passwordHelp,setPasswordHelp] = useState(''),
        [confirmPasswordHelp,setConfirmPasswordHelp] = useState(''),
        [isLogin,setIsLogin] = useState(true),
        [listener,setListener] = useState(false),
        [qqLogging,setQQLogging] = useState(false),
        [submitLoading,setSubmitLoading] = useState(false);

    useEffect(()=>{
        if(!listener){
            connectContent('login',()=>{
                setListener(true)
                addListener('login',(request,sender,response)=>{
                    props.changeUser({...props.user,...request})
                    props.onClose({message:'logged in',token:request.token})
                    response()
                })
            })
        }
    },[])

    /**
     * 用户登录处理逻辑
     */
    const handleUserLogin = (t)=>{
        const username = form.getFieldValue('username'),
            password = form.getFieldValue('password')
        userLogin(username,password).then(res=>{
            if(res.code===10000){
                props.changeUser({...props.user,...res.data})
                form.resetFields()
                props.onClose({message:'logged in',token:res.data.token})
            }else if(res.code===10005 || res.code===20001){
                setPasswordValidateStatus('error')
                setPasswordHelp('tip.usernameOrPasswordError')
            }
            setSubmitLoading(false)
        })
    }

    /**
     * 注册处理逻辑
     */
    const handleUserRegister = ()=>{
        const username = form.getFieldValue('username'),
            password = form.getFieldValue('password')
        userRegister(username,password).then(res=>{
            if(res.code===10000){
                props.changeUser({...props.user,...res.data})
                props.backup()
                form.resetFields()
                props.onClose({message:'logged in',token:res.data.token})
            }else if(res.code===10002){
                setConfirmPasswordValidateStatus('error')
                setConfirmPasswordHelp('tip.passwordTypeError')
            }else if(res.code===20003){
                setConfirmPasswordValidateStatus('error')
                setConfirmPasswordHelp('tip.usernameIsExist')
            }
            setSubmitLoading(false)
        })
    }

    /**
     * 校验用户名
     */
    const handleUsernameValidator = (t)=>{
        return ({})=>({
            async validator(_,value){
                if(!value){
                    throw new Error(t('tip.usernameRequired'))
                }else if(!isLogin){
                    const result = await checkUsernameIsExist(value)
                    if(result.code === 10000){

                    }else if(result.code === 10002) {
                        if (result.data) throw new Error(result.msg)
                    }else if(result.code === 20003){
                        if(result.data) throw new Error(t('tip.usernameIsExist'))
                    }else{
                        throw new Error(t('tip.networkError'))
                    }
                }
            }
        })
    }

    /**
     * 校验密码
     */
    const handlePasswordValidator = (t)=>{
        return ({})=>({
            async validator(_,value){
                let help = '',status = 'success'
                if(!value){
                    help = 'tip.passwordRequired'
                    status = 'error'
                }
                setPasswordHelp(help)
                setPasswordValidateStatus(status)
                if(status==='error'){
                   throw new Error(help)
                }
            }
        })
    }

    /**
     * 校验确认密码
     */
    const handleConfirmPasswordValidator = (t)=>{
        return ({})=>({
            async validator(_,value){
                let help='',
                    status ='success',
                    password = form.getFieldValue('password')
                if(!value){
                    help = 'tip.confirmPasswordRequired'
                    status = 'error'
                }else if(value!==password){
                    help = 'tip.confirmPasswordDifferent'
                    status = 'error'
                }
                setConfirmPasswordHelp(help)
                setConfirmPasswordValidateStatus(status)
                if(status==='error'){
                    throw new Error(help)
                }
            }
        })
    }

    /**
     * 重置表单提示信息（清空）
     */
    const resetHelp = (type='all')=>{
        switch(type){
            case 'all':
                setPasswordValidateStatus('success')
                setConfirmPasswordHelp('success')
                setPasswordHelp('')
                setConfirmPasswordHelp('')
                return
            case 'password':
                setPasswordValidateStatus('success')
                setPasswordHelp('')
            case 'confirmPassword':
                setConfirmPasswordHelp('success')
                setConfirmPasswordHelp('')
        }
    }

    /**
     * 打开QQ登录窗口
     */
    const handleOpenQQLoginWindow = ()=>{
        let width = parseInt(window.innerWidth/2 + ''),
            height = parseInt(window.innerHeight/2 +''),
            left = window.screenLeft+width,
            top = window.screenTop+100
        width = width<500?500:width
        height = height<500?500:height
        setQQLogging(true)
        const loginToken = nanoid()
        const subWindow = window.open(props.user.qqLoginUri+loginToken,'QQ Login',`width=${width},height=${height},top=${top},left=${left}`)
        checkLogin(loginToken)
        function checkLogin(loginToken){
            return
            let i = 0
            qqLoginTimer = setInterval(async ()=>{
                if(i>200) clearInterval(qqLoginTimer)
                const result = await qqLoginCheck(loginToken)
                if(result?.code===10000){
                    props.changeUser({...props.user,...result.data})
                    qqLoginCancel()
                    subWindow.close()
                    props.onClose()
                }
                i++
            },500)
        }
    }

    /**
     * 取消QQ登录
     */
    const qqLoginCancel = ()=>{
        setQQLogging(false);
        clearInterval(qqLoginTimer)
    }

    /**
     * 登录/注册表单提交
     */
    const onFinish = (e)=>{
        if(isLogin){
            handleUserLogin()
        }else{
            handleUserRegister()
        }
        setSubmitLoading(true)
    }

    return <Translation>
        {
            t=> <div id='login-form' className={props.className}>
                <div className='content'>
                    <Tabs defaultActiveKey="1" onChange={()=>{qqLoginCancel()}}>
                        <TabPane tab={t('user.quickLogin')} key="1">
                            <div className='login-content'>
                                <Spin spinning={qqLogging}
                                      indicator={<LoadingIcon type='qq' onCancel={qqLoginCancel}/>}>
                                    <div className='login-qq' onClick={handleOpenQQLoginWindow}>
                                        <QqOutlined />
                                        {t('user.qqQuickLogin')}
                                    </div>
                                </Spin>
                            </div>
                        </TabPane>

                        <TabPane tab={t(isLogin?'user.accountLogin':'user.accountRegister')} key="2">
                            <div className='login-content'>
                                <div className='login-account'>
                                    <Form
                                        name="login"
                                        layout='vertical'
                                        form={form}
                                        onFinish={onFinish}
                                        validateTrigger='onSubmit'
                                        autoComplete="off"
                                    >
                                        <Form.Item
                                            label={t('word.username')}
                                            name="username"
                                            required={true}
                                            rules={[
                                                handleUsernameValidator(t)
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item
                                            label={t('word.password')}
                                            name="password"
                                            required={true}
                                            validateStatus={passwordValidateStatus}
                                            help={t(passwordHelp)}
                                            rules={[handlePasswordValidator(t)]}
                                        >
                                            <Input.Password />
                                        </Form.Item>

                                        {
                                            !isLogin?<Form.Item
                                                label={t('user.confirmPassword')}
                                                name="confirmPassword"
                                                required={true}
                                                validateStatus={confirmPasswordValidateStatus}
                                                help={t(confirmPasswordHelp)}
                                                rules={[
                                                    handleConfirmPasswordValidator(t)
                                                ]}
                                            >
                                                <Input.Password />
                                            </Form.Item>:null
                                        }

                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" loading={submitLoading}>
                                                {t('word.submit')}
                                            </Button>
                                            <Button type='link' onClick={()=>{setIsLogin(!isLogin);resetHelp()}}>
                                                {t(isLogin?'user.userRegister':'user.userLogin')}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </TabPane>
                    </Tabs>

                    <CloseOutlined onClick={e=>{
                        qqLoginCancel()
                        props.onClose(e)
                    }}/>
                </div>
            </div>
        }
    </Translation>
}

export default connect(
    (state)=>({
        user:state.user,
        system:state.system
    }),
    {
        changeUser:data=>({type:'changeUser',data}),
        backup:()=>({type:'backup'})
    }
)(LoginForm)