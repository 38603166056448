/**
 * 获取本地storage储存(原生方法)
 * @param key 储存的键名
 * @returns {any}
 */
export const getLocalStorage = (key)=>{
    return JSON.parse(localStorage.getItem(key) || 'null')
}

/**
 * 设置本地storage储存(原生方法)
 * @param key 要储存的数据的键
 * @param storage 要储存的数据
 */
export const setLocalStorage = (key,storage)=>{
    if(storage===undefined){
        localStorage.removeItem(key)
    }else{
        localStorage.setItem(key,JSON.stringify(storage))
    }
}

/**
 * 获取本地storage储存
 * @param key 储存的键名
 * @returns {any}
 */
export const getLocalStorage1 = (key)=>{
    return JSON.parse(localStorage.getItem(key) || 'null')
}

/**
 * 设置本地storage储存
 * @param key 要储存的数据的键
 * @param storage 要储存的数据
 */
export const setLocalStorage1 = (key,storage)=>{
    if(storage===undefined){
        localStorage.removeItem(key)
    }else{
        localStorage.setItem(key,JSON.stringify(storage))
    }
}

/**
 * 获取本地storage储存
 * @param key 储存的键名
 * @param cb 回调函数
 * @returns {any}
 */
export const getSyncStorage = async (key)=>{
    return new Promise((resolve)=>{
        try{
            if(!key){
                window.chrome.storage.sync.get(null,items=>{
                    return resolve(items)
                })
            }else{
                window.storage.sync.get([key],result=>{
                    return resolve(result[key])
                })
            }
        }catch(e){
            window.storage.sync.get(null,result=>{
                return resolve(result[key])
            })
        }
    })
}

/**
 * 设置本地storage储存
 * @param data 要储存的数据的键｜数据对象
 * @param storage 要储存的数据
 */
export const setSyncStorage = async (data,storage=undefined)=>{
    return new Promise(resolve=>{
        if(typeof data === 'string'){
            window.storage.sync.set({key:storage},()=>{
                resolve()
            })
        }else if(data instanceof Object){
            window.storage.sync.set(data,()=>{
                resolve()
            })
        }
    })
}

/**
 * storage更改监听
 */
export const watchStorage = async ()=>{
    return new Promise((resolve)=>{
        window.storage.onChanged.addListener(function (changes) {
            resolve(changes.options?.newValue)
        });
    })
}