import {connect} from "react-redux";
import {getTheme} from '../../api'
import {useEffect} from "react";

function Background(props){
    useEffect(()=>{
        return
        if(!props.wallpaper.wallpapers
            || !props.wallpaper.updateTime
            || props.wallpaper.updateTime<(Date.now()-1000*60*60*24*7)){
            getWallpaper(props.wallpaper.theme,!props.wallpaper.updateTime)
        }
    },[props.wallpaper.theme, props.wallpaper.init,props.wallpaper.wallpaper])

    useEffect(()=>{
        if(props.wallpaper.loading){
            const newImage = new Image()
            const complete = ()=>{
                if(newImage.complete) {
                    props.changeWallpaper({...props.wallpaper,loading:false})
                    clearInterval(interval)
                }
            }
            newImage.src = props.wallpaper.wallpaper
            const interval = setInterval(complete,1)
        }
    },[props.wallpaper.wallpaper])

    /**
     * 获取主题的壁纸
     * @param typeId type ID
     * @param updateWallpaper 是否更新
     */
    const  getWallpaper = (typeId,updateWallpaper)=>{
        getTheme(typeId).then(data=>{
            const wallpaper = {...props.wallpaper}
            wallpaper.wallpapers = data.map(item=>'https:'+item.cover)
            wallpaper.updateTime = Date.now()
            if(!wallpaper.wallpaper || updateWallpaper){
                wallpaper.wallpaper = wallpaper.wallpapers[0]
            }
            props.changeWallpaper(wallpaper)
        })
    }

    return props.wallpaper.wallpaper?
        <div className='background' style={{backgroundImage:`url("${ props.wallpaper.wallpaper}")`}} />:
        null
}

export default connect(
    (state)=>({wallpaper:state.wallpaper}),
    {
        changeWallpaper:(data)=>({type:'changeWallpaperStorage',data})
    }
)(Background)
