import {connect} from "react-redux";

function LeftTop(props){
    return null
}

export default connect(
    (state)=>({
        setting:state.setting
    }),
    {
        changeSettingDrawer:data=>({type:'changeSettingDrawer',data}),
    }
)(LeftTop)