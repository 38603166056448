import {Drawer, Tag, Tabs, Typography, Popconfirm} from "antd";
import {CloseOutlined, LinkOutlined, CheckCircleFilled} from "@ant-design/icons";
import {Translation} from 'react-i18next'
import {connect} from 'react-redux'
import Custom from "./custom";
import searchDefaultIcon from '../../assets/img/search_icon_default.png'
import LoadingIcon from '../../assets/img/1px.png'
import {handleImgLoad} from './index'
import './drawer.css'
import {searchEngineForage} from "../../plugins/localforage";

const {TabPane} = Tabs;

function SearchDrawer(props) {
    const
        search = props.search,
        searchList = search.searchEngineList,
        {Paragraph} = Typography

    /**
     * 移除搜索引擎(取消使用)
     */
    const handleRemove = (engine)=>{
        return ()=>{
            const searchEngineUse = search.searchEngineUse.filter(use=>use!==engine.id)
            props.changeSearch({...search,searchEngineUse})
        }
    }

    /**
     * 删除搜索引擎
     */
    const handleDelete = (engine)=>{
        return ()=>{
            const _search = {...search}
            if(engine.isLocalIcon){
                searchEngineForage.removeItem(engine.icon)
            }
            _search.searchEngineUse = _search.searchEngineUse.filter(use=>use!==engine.id)
            _search.searchEngineList = _search.searchEngineList.filter(search=>search.id!==engine.id)
            props.changeSearch(_search)
            handleDeleteCancel() // 取消tagGroup显示
        }
    }

    /**
     * 单击了删除按钮
     */
    const handleDeleteClick = (e)=>{
        const parent = e.target.parentElement
        if(parent.className?.split(' ').includes('tag-group')){
            parent.style.opacity = '1'
        }
    }
    /**
     * 取消删除
     */
    const handleDeleteCancel = ()=>{
        const all = document.querySelectorAll('.tag-group')
        all.forEach(tagGroup=>{
            tagGroup.style.opacity = ''
        })
    }

    /**
     * 添加搜索引擎
     */
    const handleAdd = (engine)=>{
        return ()=>{
            const searchEngineUse = [...search.searchEngineUse,engine.id]
            props.changeSearch({...search,searchEngineUse})
        }
    }

    /**
     * 切换搜索引擎
     */
    const handleUse = (engine)=>{
        return ()=>{
            const storage = {...search}
            storage.current = engine.id
            storage.searchEngineUse = [...storage.searchEngineUse,engine.id]
            props.changeSearch(storage)
        }
    }

    return (
        <Translation>
            {t=>(
                <Drawer id='search-drawer'
                        width='100%'
                        placement='left'
                        visible={true}
                        onClose={props.onClose}
                        closable={false}>
                    <CloseOutlined className='close-btn' onClick={props.onClose} />
                    <Tabs defaultActiveKey="1">
                        <TabPane tab={t('search.list')} key="1">
                            <ul className='search-list'>
                                { searchList.map(engine=>{
                                    return (
                                        // padding engine.id
                                        <li key={engine.id}
                                            className={`${
                                                search.searchEngineUse.includes(engine.id)? 'active':'' 
                                            }`}>
                                            <div className='icon'>
                                                <img onLoad={handleImgLoad()}
                                                     data-src={engine.img || searchDefaultIcon}
                                                     src={LoadingIcon}
                                                     alt={engine.name}/>
                                                {
                                                    search.current===engine.id? <CheckCircleFilled />:null
                                                }
                                            </div>
                                            <div className='content'>
                                                <span className='name'>{t(engine.name)}</span>
                                                <Paragraph ellipsis={{rows:2, expandable:true, symbol:t('word.more')}}>
                                                    {engine.info || t('search.defaultInfo')}
                                                </Paragraph>
                                            </div>
                                            <div className='tag-group'>
                                                <Tag color="#4a9f08" className='use' onClick={handleUse(engine)}>
                                                    {search.current===engine.id?t('word.using'):t('word.use')}
                                                </Tag>
                                                {
                                                    search.searchEngineUse.includes(engine.id)?
                                                        search.current===engine.id?
                                                            null:
                                                            <>
                                                                <Tag color="#f50"
                                                                     onClick={handleRemove(engine)}>
                                                                    {t('word.remove')}
                                                                </Tag>
                                                            </>:
                                                        <Tag color="#2db7f5"
                                                             onClick={handleAdd(engine)}>
                                                            {t('word.add')}
                                                        </Tag>
                                                }
                                                {
                                                    engine.isCustom && search.current!==engine.id?
                                                        <Popconfirm
                                                            placement="topRight"
                                                            title={ t('search.deleteConfirm') .replace(/\%s/,engine.name) }
                                                            onConfirm={handleDelete(engine)}
                                                            onCancel={handleDeleteCancel}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Tag onClick={handleDeleteClick}>{t('word.delete')}</Tag>
                                                        </Popconfirm>:
                                                        null
                                                }
                                                <a className='test-url'
                                                   href={engine.href.replace(/\{query\}/,'picknewtab')}
                                                   target='_blank'>
                                                    <LinkOutlined />
                                                </a>
                                            </div>
                                        </li>
                                    )
                                }) }
                            </ul>
                        </TabPane>
                        <TabPane tab={t('search.custom')} key="2">
                            <Custom/>
                        </TabPane>
                    </Tabs>
                </Drawer>
            )}
        </Translation>
    )
}

export default connect(
    (state)=>({search:state.search}),
    {
        changeSearch:(data)=>({type:'changeSearch',data})
    }
)(SearchDrawer)